.portfolio__labels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10vh;
    margin-bottom: 5vh;
    width: 400px;
  }
  
  .portfolio__labels a {
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    
  }
  .portfolio__container {
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .portfolio__container span {
    border: 1px solid white;
    padding: 5px;
  }
  h3{
    color : white;
  }
.skill-section{
  background-color: #212529;

}

.container_button{
  display:flex;
justify-content:space-evenly;
list-style: none;

}
.container_button li{
  cursor: pointer;
  color : white;
  font-weight: bold;
  padding : 10px 10px;
}
 .white{
  cursor: pointer;
  font-weight: bold;
  color :#0F0D19 !important;
  background-color: #FBFAFC !important;
  border-radius: 10px;
  padding : 10px 10px;
}

.project_container{
  display : flex
}

.project-card{
  cursor: pointer;
}